/* eslint-disable @next/next/no-img-element */
import React, { FC, useEffect, useState } from 'react'

// MUI
import { Box } from '@mui/material'

// Swiperjs
import { Swiper, SwiperSlide } from 'swiper/react'
import { Autoplay } from 'swiper/modules'
import 'swiper/css/autoplay'

// API
import { retrieveAllAds } from '@/Helpers'

// interface
import { AdsResp } from '@/interface'

const Ads: FC = () => {
  const [adsList, setAdsList] = useState<AdsResp[]>([])

  useEffect(() => {
    ;(async () => {
      await retrieveAllAds({ onSuccess: setAdsList })
    })()
  }, [])

  return (
    <Box>
      <Swiper
        loop
        rewind
        centeredSlides
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        modules={[Autoplay]}
        className="mySwiper"
      >
        {adsList.map((ad, idx: number) => {
          return (
            <SwiperSlide key={idx}>
              <img src={ad.imgUrl} srcSet={ad.imgUrl} alt="Tiaara" style={{ width: '100%', height: 'auto' }} />
            </SwiperSlide>
          )
        })}
      </Swiper>
    </Box>
  )
}

export default Ads
