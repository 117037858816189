// MUI
import { Box, IconButton, styled } from '@mui/material'

export const ArrowIconBG = styled(Box)(({ theme }) => ({
  width: theme.typography.pxToRem(30),
  height: theme.typography.pxToRem(30),
  lineHeight: theme.typography.pxToRem(30),
  fontSize: theme.typography.pxToRem(10),
  background: 'var(--secondary-color)',
  borderRadius: '50%',
  border: `${theme.typography.pxToRem(1)} solid var(--secondary-color)`,
  display: 'grid',
  placeItems: 'center',
  color: 'var(--primary-color)',
}))

export const CustomIconButton = styled(IconButton)({
  '&:hover': {
    background: 'transparent',
  },
})
