import { FC } from 'react'

// UI
import { SectionHeading } from '@/ui'

type SectionHeadingTextProps = {
  primaryText: string
  secondaryText: string
}

export const SectionHeadingText: FC<SectionHeadingTextProps> = props => {
  const { primaryText, secondaryText } = props

  return (
    <SectionHeading variant="h6">
      <SectionHeading variant="body2" variantMapping={{ body2: 'span' }}>
        {primaryText}
      </SectionHeading>{' '}
      {secondaryText}
    </SectionHeading>
  )
}
