import { NextPage } from 'next'
import Head from 'next/head'

// MUI
import { Box, Container } from '@mui/material'

// Home Page Components
import Ads from '@/components/HomePage/Ads'
import SubCategoryList from '@/components/HomePage/SubCategList'
import NewlyArrivedProds from '@/components/HomePage/NewlyArrivedProds'
import UnderThreeNine from '@/components/HomePage/UnderThreeNine'
import UnderOneFourNineNine from '@/components/HomePage/UnderOneFourNineNine'
import UnderOneTripleNine from '@/components/HomePage/UnderOneTripleNine'
import AboveOneTripleNine from '@/components/HomePage/AboveOneTripleNine'

const Home: NextPage = () => {
  return (
    <>
      <Head>
        <title>Tiaara</title>
        <meta name="description" content="Generated by create next app" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="icon" href="/Logos/webp/Tiaara_Logo_114_114.webp" />
      </Head>

      <Ads />

      <Box component="main" sx={{ flexGrow: 1, py: 3 }} id="scroll-container">
        <Container maxWidth="xl">
          <Box sx={{ mb: 4 }}>
            <SubCategoryList />

            <NewlyArrivedProds />

            <UnderThreeNine />

            <UnderOneFourNineNine />

            <UnderOneTripleNine />

            <AboveOneTripleNine />
          </Box>
        </Container>
      </Box>
    </>
  )
}

export default Home
