import { Box, Typography, styled } from '@mui/material'

export const ProductCartWrap = styled(Box)(({ theme }) => ({
  marginBottom: theme.typography.pxToRem(30),
  position: 'relative',
  background: '#FFFF',
  border: `${theme.typography.pxToRem(1)} solid var(--secondary-color)`,
  borderRadius: theme.typography.pxToRem(25),
  overflow: 'hidden',
  transition: 'all .2s ease',

  [theme.breakpoints.up('md')]: {
    maxWidth: theme.typography.pxToRem(330),
  },
  [theme.breakpoints.down('sm')]: {
    maxWidth: '100%',
  },

  '&:hover .prod-action-1': {
    opacity: 1,
    visibility: 'visible',
  },
}))

export const ProductImgActionWrap = styled(Box)(({ theme }) => ({
  position: 'relative',
  background: '#FFF',
  padding: `${theme.typography.pxToRem(10)} ${theme.typography.pxToRem(12)}`,
  overflow: 'hidden',
  maxWidth: theme.typography.pxToRem(320),
  margin: '0 auto',
}))

export const ProductImg = styled(Box)(({ theme }) => ({
  position: 'relative',
  overflow: 'hidden',
  borderRadius: theme.typography.pxToRem(20),
  border: `${theme.typography.pxToRem(1)} solid var(--secondary-color)`,

  '&:hover img': {
    transform: 'scale(1.1)',
    transformOrigin: 'center center',
    borderRadius: theme.typography.pxToRem(10),
  },
}))

export const ProductImage = styled('img')(({ theme }) => ({
  transition: 'all 3s ease',
  width: '100%',
  height: theme.typography.pxToRem(350),
  maxHeight: '100%',
  objectFit: 'cover',
  borderRadius: theme.typography.pxToRem(10),
}))

export const ProductBadge = styled(Box)(({ theme }) => ({
  position: 'absolute',
  left: theme.typography.pxToRem(25),
  top: theme.typography.pxToRem(22),
  zIndex: 9,
  display: 'flex',
}))

export const ProductActionOne = styled(Box)({
  position: 'absolute',
  left: '45%',
  top: '50%',
  transform: 'translateX(-45%), translateY(-50%)',
  opacity: 0,
  visibility: 'hidden',
  transition: 'all 3s ease 0s',
  zIndex: 9,
})

export const ViewIcon = styled(Box)(({ theme }) => ({
  width: theme.typography.pxToRem(40),
  height: theme.typography.pxToRem(40),
  lineHeight: theme.typography.pxToRem(44),
  borderRadius: theme.typography.pxToRem(50),
  background: '#e8f6ea',
  position: 'relative',
  display: 'grid',
  placeItems: 'center',
  border: `${theme.typography.pxToRem(1)} solid var(--secondary-color)`,
}))

export const NewSpan = styled(Typography)(({ theme }) => ({
  background: '#99bbad',
  fontFamily: 'var(--accent-font)',
  display: 'inline-block',
  fontSize: theme.typography.pxToRem(12),
  lineHeight: 1,
  borderRadius: theme.typography.pxToRem(40),
  color: '#FFF',
  padding: `${theme.typography.pxToRem(5)} ${theme.typography.pxToRem(10)}`,
}))

export const ProductContentWrap = styled(Box)(({ theme }) => ({
  padding: `0 ${theme.typography.pxToRem(20)} ${theme.typography.pxToRem(15)}`,
}))

export const BrandName = styled(Typography)(({ theme }) => ({
  fontFamily: 'var(--accent-font)',
  color: '#90908e',
  fontSize: theme.typography.pxToRem(12),
  padding: `${theme.typography.pxToRem(10)} 0`,
}))

export const ProductName = styled(Typography)(({ theme }) => ({
  fontFamily: 'var(--source-code-pro-font)',
  fontSize: theme.typography.pxToRem(18),
  fontWeight: 600,
  color: '#222',
  lineHeight: 1.2,
  padding: `0 0 ${theme.typography.pxToRem(10)} 0`,
}))

export const ProductPrice = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(16),
  fontWeight: 700,
  fontFamily: 'var(--accent-font)',
  color: '#088178',
}))

export const AddToCartBG = styled(Box)(({ theme }) => ({
  width: theme.typography.pxToRem(40),
  height: theme.typography.pxToRem(40),
  lineHeight: theme.typography.pxToRem(40),
  fontSize: theme.typography.pxToRem(13),
  borderRadius: theme.typography.pxToRem(50),
  backgroundColor: '#e8f6ea',
  textAlign: 'center',
  display: 'grid',
  placeItems: 'center',
  border: `${theme.typography.pxToRem(1)} solid var(--secondary-color)`,

  '& .MuiSvgIcon-root': {
    color: 'var(--primary-color)',
  },
}))
