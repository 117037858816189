import { FC } from 'react'

// Nextjs
import { useRouter } from 'next/router'

// MUI
import { Grid, IconButton, Tooltip } from '@mui/material'
import { Box } from '@mui/joy'
import VisibilityIcon from '@mui/icons-material/Visibility'
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart'

// Helpers
import { useAuth } from '@/hooks/use-auth'

// Helpers
import { addProductToCart } from '@/Helpers'

// UI
import { NextLink } from '@/ui'
import {
  AddToCartBG,
  BrandName,
  NewSpan,
  ProductActionOne,
  ProductBadge,
  ProductCartWrap,
  ProductContentWrap,
  ProductImage,
  ProductImg,
  ProductImgActionWrap,
  ProductName,
  ProductPrice,
  ViewIcon,
} from './styles'

type ProductCardProps = {
  pId: string
  imgPath: string
  brandName: string
  pName: string
  price: number
  link: string
  needShowNewTag: boolean
}

export const ProductCard: FC<ProductCardProps> = props => {
  const { brandName, imgPath, link, pId, pName, price, needShowNewTag } = props

  const router = useRouter()

  const { isAuthenticated } = useAuth()

  return (
    <Box>
      <ProductCartWrap>
        <ProductImgActionWrap>
          <ProductImg>
            <NextLink href={link} aria-label={`${pName} Page`} title={pName}>
              <ProductImage src={imgPath} srcSet={imgPath} alt={pName} title={pName} />
            </NextLink>
          </ProductImg>

          <ProductActionOne className="prod-action-1">
            <NextLink href={link} aria-label={`${pName} Page`} title={pName}>
              <ViewIcon>
                <VisibilityIcon />
              </ViewIcon>
            </NextLink>
          </ProductActionOne>

          {needShowNewTag ? (
            <ProductBadge>
              <NewSpan variant="body2" variantMapping={{ body2: 'span' }}>
                New
              </NewSpan>
            </ProductBadge>
          ) : null}
        </ProductImgActionWrap>
        <ProductContentWrap>
          <BrandName>{brandName}</BrandName>

          <ProductName variant="h2">
            <NextLink href={link} aria-label={`${pName} Page`} title={pName}>
              {pName}
            </NextLink>
          </ProductName>

          <Grid container justifyContent="space-between" alignItems="center" spacing={4}>
            <Grid item>
              <ProductPrice variant="body2" variantMapping={{ body2: 'span' }}>
                {new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR' }).format(price)}
              </ProductPrice>
            </Grid>
            <Grid item>
              <IconButton
                sx={{ '&:hover': { background: 'transparent' } }}
                onClick={() => {
                  addProductToCart(router, { pQuantity: 1, perProductPrice: price, productDetailsFk: pId }, isAuthenticated)
                }}
              >
                <Tooltip title="Add to Cart" arrow>
                  <AddToCartBG>
                    <AddShoppingCartIcon fontSize="small" color="inherit" />
                  </AddToCartBG>
                </Tooltip>
              </IconButton>
            </Grid>
          </Grid>
        </ProductContentWrap>
      </ProductCartWrap>
    </Box>
  )
}
