import React, { FC, useEffect, useState } from 'react'

// MUI
import { Box, Container, Grid, Stack } from '@mui/material'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'

// Swiper
import { Swiper, SwiperSlide } from 'swiper/react'
import { Autoplay, Navigation } from 'swiper/modules'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/autoplay'

// API
import { retrieveSubCategApi } from '@/Helpers'

// Interface
import { SubCategResp } from '@/interface'

// Common
import { SectionHeadingText } from '@/components/common/SectionHeadingText'

// UI
import { NextLink } from '@/ui'
import { ArrowIconBG, Card, CustomIconButton, Figure, SubCategImg, SubCategoryName } from './styles'

const SubCategoryList: FC = () => {
  const [subCategList, setSubCategList] = useState<SubCategResp[]>([])

  useEffect(() => {
    ;(async () => {
      await retrieveSubCategApi({ onSuccess: setSubCategList })
    })()
  }, [])

  return (
    <Box sx={{ py: 5 }}>
      <Container maxWidth="xl">
        <Box sx={{ mb: 2 }}>
          <Grid container justifyContent="space-between" alignItems="center" spacing={4}>
            <Grid item>
              <SectionHeadingText primaryText="Popular" secondaryText="Categories" />
            </Grid>
            <Grid item>
              <Box>
                <Stack direction="row" gap={1}>
                  <CustomIconButton className="categ-prev-button" aria-label="Swiper Navigation Previous Button">
                    <ArrowIconBG>
                      <ChevronLeftIcon />
                    </ArrowIconBG>
                  </CustomIconButton>
                  <CustomIconButton className="categ-next-button" aria-label="Swiper Navigation Next Button">
                    <ArrowIconBG>
                      <ChevronRightIcon />
                    </ArrowIconBG>
                  </CustomIconButton>
                </Stack>
              </Box>
            </Grid>
          </Grid>
        </Box>

        <Swiper
          loop
          rewind
          slidesPerView={1}
          spaceBetween={32}
          autoplay={{ delay: 2500, pauseOnMouseEnter: true }}
          navigation={{ nextEl: '.categ-next-button', prevEl: '.categ-prev-button' }}
          breakpoints={{
            0: {
              slidesPerView: 2,
              spaceBetween: 5,
            },
            340: {
              slidesPerView: 2,
              spaceBetween: 8,
            },

            768: {
              slidesPerView: 4,
              spaceBetween: 8,
            },
            992: {
              slidesPerView: 5,
              spaceBetween: 8,
            },
            1280: {
              slidesPerView: 5,
              spaceBetween: 10,
            },
          }}
          modules={[Autoplay, Navigation]}
        >
          {subCategList.map((categ, idx: number) => {
            return (
              <SwiperSlide key={idx}>
                <Card>
                  <Figure>
                    <NextLink href={`/category/${categ.productSubCategoryId}`}>
                      <SubCategImg src={categ?.fileUploads[0]?.fileUploadUrl} srcSet={categ?.fileUploads[0]?.fileUploadUrl} alt={categ.productSubCategoryName} title={categ.productSubCategoryName} />
                    </NextLink>
                  </Figure>

                  <NextLink href={`/category/${categ.productSubCategoryId}`}>
                    <SubCategoryName variant="h5">{categ.productSubCategoryName}</SubCategoryName>
                  </NextLink>
                </Card>
              </SwiperSlide>
            )
          })}
        </Swiper>
      </Container>
    </Box>
  )
}

export default SubCategoryList
