// MUI
import { Box, IconButton, Typography, styled } from '@mui/material'

export const ArrowIconBG = styled(Box)(({ theme }) => ({
  width: theme.typography.pxToRem(30),
  height: theme.typography.pxToRem(30),
  lineHeight: theme.typography.pxToRem(30),
  fontSize: theme.typography.pxToRem(10),
  background: 'var(--secondary-color)',
  borderRadius: '50%',
  border: `${theme.typography.pxToRem(1)} solid var(--secondary-color)`,
  display: 'grid',
  placeItems: 'center',
  color: 'var(--primary-color)',
}))

export const CustomIconButton = styled(IconButton)({
  '&:hover': {
    background: 'transparent',
  },
})

export const Card = styled(Box)(({ theme }) => ({
  background: '#FFF',
  textAlign: 'center',
  border: `${theme.typography.pxToRem(1)} solid var(--secondary-color)`,
  borderRadius: theme.typography.pxToRem(20),
  padding: theme.typography.pxToRem(10),

  [theme.breakpoints.up('md')]: {
    maxWidth: theme.typography.pxToRem(250),
  },
  [theme.breakpoints.down('sm')]: {
    maxWidth: '100%',
  },
}))

export const Figure = styled('figure')(({ theme }) => ({
  borderRadius: theme.typography.pxToRem(10),
  margin: theme.typography.pxToRem(10),
  padding: 0,
  position: 'relative',
  overflow: 'hidden',

  '&:hover img': {
    transform: 'scale(1.1)',
    transformOrigin: 'center center',
    borderRadius: theme.typography.pxToRem(10),
  },
}))

export const SubCategImg = styled('img')(({ theme }) => ({
  borderRadius: theme.typography.pxToRem(10),
  objectFit: 'cover',
  maxWidth: '100%',
  height: theme.typography.pxToRem(250),
  transition: 'transform 2s ease',
}))

export const SubCategoryName = styled(Typography)(({ theme }) => ({
  fontFamily: 'var(--source-code-pro-font)',
  margin: `${theme.typography.pxToRem(20)} 0`,
  fontSize: theme.typography.pxToRem(18),
  color: '#222',
  fontWeight: 600,
  lineHeight: 1.2,
}))
