import React, { FC, useEffect, useState } from 'react'

// MUI
import { Box, Container, Grid, Stack } from '@mui/material'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'

// Swiper
import { Swiper, SwiperSlide } from 'swiper/react'
import { Autoplay, Navigation } from 'swiper/modules'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/autoplay'

// API
import { getAllProductsOfCertainPrice } from '@/Helpers'

// Interface
import { NewlyArrivedProdsResp } from '@/interface'

// Common
import { SectionHeadingText } from '@/components/common/SectionHeadingText'
import { ProductCard } from '@/components/common/ProductCard'

// UI
import { NextLink, DrawBtn } from '@/ui'
import { ArrowIconBG, CustomIconButton } from './styles'

const AboveOneTripleNine: FC = () => {
  const [productsList, setProductsList] = useState<NewlyArrivedProdsResp[]>([])
  const [, setCount] = useState<number>(0)

  useEffect(() => {
    ;(async () => {
      await getAllProductsOfCertainPrice({ isUnderPrice: false, loading: null, onSuccess: setProductsList, pageNumber: 0, pageSize: 8, priceAmt: 1999, totalCount: setCount })
    })()
  }, [])

  return (
    <Box sx={{ py: 5 }}>
      <Container maxWidth="xl">
        <Box sx={{ mb: 2 }}>
          <Grid container justifyContent="space-between" alignItems="center" spacing={4}>
            <Grid item>
              <SectionHeadingText primaryText="Above 1999" secondaryText="Products" />
            </Grid>
            <Grid item>
              <Box>
                <Stack direction="row" gap={1}>
                  <CustomIconButton className="above-one-triple-nine-prev-button" aria-label="Swiper Navigation Previous Button">
                    <ArrowIconBG>
                      <ChevronLeftIcon />
                    </ArrowIconBG>
                  </CustomIconButton>
                  <CustomIconButton className="above-one-triple-nine-next-button" aria-label="Swiper Navigation Next Button">
                    <ArrowIconBG>
                      <ChevronRightIcon />
                    </ArrowIconBG>
                  </CustomIconButton>
                </Stack>
              </Box>
            </Grid>
          </Grid>
        </Box>

        <Swiper
          loop
          rewind
          slidesPerView={1}
          spaceBetween={32}
          autoplay={{ delay: 2500, pauseOnMouseEnter: true }}
          navigation={{ nextEl: '.above-one-triple-nine-next-button', prevEl: '.above-one-triple-nine-prev-button' }}
          breakpoints={{
            0: {
              slidesPerView: 1,
              spaceBetween: 5,
            },
            340: {
              slidesPerView: 1,
              spaceBetween: 10,
            },

            768: {
              slidesPerView: 3,
              spaceBetween: 10,
            },
            992: {
              slidesPerView: 4,
              spaceBetween: 8,
            },
            1280: {
              slidesPerView: 4,
              spaceBetween: 20,
            },
          }}
          modules={[Autoplay, Navigation]}
        >
          {productsList.map((product, idx: number) => {
            return (
              <SwiperSlide key={idx}>
                <ProductCard
                  brandName={product?.brandName}
                  imgPath={product?.fileUploads[0]?.fileUploadUrl}
                  link={`/product/${product.productId}`}
                  pId={product?.productId}
                  pName={product?.productName}
                  price={product?.productPrice}
                  needShowNewTag
                  key={idx}
                />
              </SwiperSlide>
            )
          })}
        </Swiper>

        <Box sx={{ mt: 3, display: 'grid', placeItems: 'center' }}>
          <NextLink href={`/products?isUnderPrice=${false}&priceAmt=${1999}`} aria-label="Above 999 Products" title="Above 999 Products">
            <DrawBtn type="button">View More</DrawBtn>
          </NextLink>
        </Box>
      </Container>
    </Box>
  )
}

export default AboveOneTripleNine
